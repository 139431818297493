import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Featured Projects",
    paragraph: "These are projects.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Weather App
                </div>
                <a href="https://github.com/CrankyHippo/react-weather-app">
                  <h3 className="mt-0 mb-12">OpenWeatherMap API App</h3>
                </a>
                <p className="m-0">
                  Check the current weather on any city on the planet. Switch
                  between metric and imperial units.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <a href="https://react-weather-app-bice.vercel.app/">
                  <Image
                    src={require("./../../assets/images/animated_weather.gif")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  React Calc
                </div>
                <a href="https://github.com/CrankyHippo/calculator">
                  <h3 className="mt-0 mb-12">Simple React Calulator</h3>
                </a>
                <p className="m-0">
                  Practice using React with components, a straight forward
                  calulator app utilizing HTML, CSS, Javascript, while designed for functional simplicity.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom rounded-lg",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <a href="https://calculator-hippo.netlify.app/">
                  <Image
                    src={require("./../../assets/images/animated_calc.gif")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Restaurant Reservation Application
                </div>
                <a href="https://github.com/CrankyHippo/restaurant-reservation">
                  <h3 className="mt-0 mb-12">Full Stack App Capstone</h3>
                </a>
                <p className="m-0">
                  My fullstack capstone project from Thinkful's Coding Bootcamp,
                  a restaurant reservation, using React, Node, Express, and
                  PostgreSQL.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <a href="https://restraunt-frontend.vercel.app/dashboard">
                  <Image
                    src={require("./../../assets/images/reservation.gif")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  MotoRidin
                </div>
                <a href="https://github.com/CrankyHippo/motoridin-blog">
                  <h3 className="mt-0 mb-12">Personal Motorcycle Blog</h3>
                </a>
                <p className="m-0">
                  My personal motorcycle and mountain bike blog. A fully
                  responsive CMS Blog App, utilizing React, JS, Tailwindcss,
                  GraphQL and GraphCMS for a dedicated Content Management
                  System.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <a href="https://www.motorid.in/">
                  <Image
                    src={require("./../../assets/images/motoridin.gif")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  BC Carpentry
                </div>
                <a href="https://github.com/CrankyHippo/bc-carpentry">
                  <h3 className="mt-0 mb-12">Business Landing Page</h3>
                </a>
                <p className="m-0">
                  Designed and deployed landing page for local business. Created
                  per client ask and needs, using tailwindcss, nextjs, and
                  react.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <a href="https://bc-carpentry.vercel.app/">
                  <Image
                    src={require("./../../assets/images/bccarpentry.gif")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
